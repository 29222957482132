import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Image, Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/microsoft";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import About, { SCards, SCardsHolder } from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";

import History from "../../case-study/History";
import ImageCard from "../../../components/cards/ImageCard";

function Microsoft() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />
                <ClientTag caseStudy={caseStudy} bigLogo />
                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>
                <Video customAspectRatio="16/10" id="1071533417?h=77985d6985" />

                <About caseStudy={caseStudy} style={{ marginBottom: -70 }}>
                    <a href="https://aiotlabs.microsoft.com/en" target="_blank">
                        Microsoft Co-Innovation Labs
                    </a>
                    , known for its strategic collaborations to{" "}
                    <b>accelerate technological innovations</b>, partnered with{" "}
                    <b>Treeview</b> to design and deploy a{" "}
                    <b>Mixed Reality IoT solution</b> for{" "}
                    <a href="https://www.teyma.com/" target="_blank">
                        Teyma
                    </a>
                    , a leader in renewable energy production within the LATAM
                    region, to undertake Uruguay's most ambitious hybrid
                    renewable energy project to date. This $7 billion initiative
                    aims to develop a large-scale green hydrogen energy
                    production facility, marking a significant milestone in
                    Uruguay's energy production and sustainable energy
                    development.
                    <br />
                    <br />
                    During the feasibility phase, Teyma faced challenges in
                    effectively communicating complex data to a diverse group of
                    stakeholders, including investors, government officials, and
                    landowners. Additionally, the team needed to{" "}
                    <b>
                        accurately simulate energy outputs in a highly visual
                        and user-friendly way.
                    </b>{" "}
                    The primary objectives were to ensure transparency in
                    communication, accuracy in data, and foster engagement
                    throughout the project's lifecycle.
                </About>

                <TreeviewRole caseStudy={caseStudy}>
                    Treeview collaborated with Microsoft and Tayma to{" "}
                    <b>design an develop a digital twin MR solution</b> enabled
                    non-technical users to visualize and understand multiple
                    data layers of the renewable energy production site,
                    including live wind and solar visual data, the project's
                    real-time energy production, complex capacity factor, and
                    additional technical data.
                    <br />
                    <br />
                    The digital twin solution also allowed users to inspect
                    specific equipment by visualizing additional layers of
                    information. The primary MR headset used was the{" "}
                    <b>Microsoft Hololens 2</b>, and the solution was also
                    available on iPad via ARKit, as well as through a web
                    deployment solution.
                    <div
                        style={{ margin: isMobile ? "20px auto" : "64px auto" }}
                    >
                        <Image
                            image={require("../../../database/case-studies/microsoft/treeview-role.png")}
                            contain
                            style={{
                                width: isMobile ? "90vw" : 1026,
                                height: isMobile ? "200px" : 565,
                                margin: "auto",
                            }}
                        />
                        <Text
                            style={{
                                marginLeft: 10,
                                marginTop: 5,
                                fontStyle: "italic",
                            }}
                            color="medium"
                            size="H7"
                            center={isMobile}
                        >
                            Microsoft, Teyma and Treeview Senior Management Team
                        </Text>
                    </div>
                    The solution was deployed at{" "}
                    <b>Microsoft's Montevideo Co-Innovation Lab</b>'s
                    anniversary and is currently being utilized by the
                    management and sales teams of Teyma as part of the
                    feasibility phase of the project. The potential future of
                    the project includes assistance with the design process of
                    the energy site and future maintenance and operations
                    support.
                    <br />
                    <br />
                    Attendance at the Microsoft Co-Innovation Lab anniversary
                    included the Vice President and ministers of industry and
                    international affairs, Microsoft's Vice President of
                    Business Development, and the Director of Co-Innovation
                    Labs. The launch was a success, showcasing and inspiring the
                    region's industries with the value of technological
                    innovation and opening the doors to future collaborations
                    between technology leaders and regional industries.
                    <SCardsHolder>
                        <SCards big grid>
                            {[
                                {
                                    image: require("./image-a.png"),
                                    caption:
                                        "Microsoft Co-Innovation Lab Montevideo Anniversary",
                                },
                                {
                                    image: require("./image-b.png"),
                                    caption:
                                        "Mixed Reality Digital Twin Simulation - Wind Turbine Data",
                                },
                                {
                                    image: require("./image-c.png"),
                                    caption:
                                        "Horacio Torrendell - CEO of Treeview ",
                                },
                                {
                                    image: require("./image-d.png"),
                                    caption:
                                        "Presentation to Microsoft Senior Management Team",
                                },
                            ].map((c, i) => (
                                <ImageCard {...c} key={i} />
                            ))}
                        </SCards>
                    </SCardsHolder>
                </TreeviewRole>
                <History caseStudy={caseStudy} />
                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default Microsoft;
